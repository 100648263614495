<template>
  <div class="section-hot container-custom">
    <div class="section-hot__title">
      <BaseImg
        src="/assets/images/components/desktop/pages/home/section-jackpot/icon-hot.svg"
        alt="ic-sport"
        class="ic-sport"
      />
      <span>Game hot</span>
    </div>
    <div class="section-hot__content">
      <div v-for="(item, index) in listGame" :key="index" class="item-game">
        <BaseImg :src="item.img" :alt="item.name" lazy class="image-game" />
        <div class="game-hover">
          <div class="overlay">
            <div class="action" @click="onClickGame(item)">
              <span>Chơi game</span>
            </div>
            <div class="try-play" @click="onClickGame(item)">Chơi thử</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import debounce from 'lodash/debounce'
import useNavigationGame from '~/composables/useNavigationGame'
import { useGameHot } from '~/composables/home/useGameHot'

const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()
const { listGame } = useGameHot()

const onClickGame = debounce((item) => {
  navigationCheckLoggedInAndOpenGame(item)
}, 200)
</script>
<style scoped lang="scss" src="assets/scss/components/desktop/pages/home/section-hot/index.scss"></style>
