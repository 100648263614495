<template>
  <div class="section-sports">
    <div class="section-sports__title">
      <BaseImg
        src="/assets/images/components/mobile/pages/home/section-sport/icon-sport.svg"
        alt="ic-sport"
        class="ic-sport"
      />
      <span>Thể thao</span>
    </div>
    <div class="section-sports__content">
      <nuxt-link
        v-for="(item, index) in LOBBY_SPORTS_MB"
        :key="index"
        class="section-sports--item"
        :class="item.class"
        :to="item.link"
      >
        <BaseImg
          :src="item.img"
          :alt="item.title"
          class="item"
          lazy
        />
        <BaseImg
          :src="item.title"
          :alt="item.title"
          class="title"
          lazy
        />
        <BaseImg
          :src="item.background"
          :alt="item.title"
          class="background"
          lazy
        />
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseImg from '~/components/common/base-img.vue'
import { LOBBY_SPORTS_MB } from '~/resources/home/section-sports.ts'
const router = useRouter()
const openLink = (link: string) => {
  router.push(link)
}

</script>
<style scoped lang="scss" src="assets/scss/components/mobile/pages/home/section-sports/index.scss">

</style>
