interface ILobbySport {
    background: string
    img: string
    title: string
    class?: string
    link: string
}
export const LOBBY_SPORTS: ILobbySport[] = [
  {
    background: '/assets/images/components/desktop/pages/home/section-sports/bg-ksport.webp',
    img: '/assets/images/components/desktop/pages/home/section-sports/ksport-item.webp',
    title: '/assets/images/components/desktop/pages/home/section-sports/ksports.webp',
    class: 'ksport',
    link: '/the-thao/ksports'
  },
  {
    background: '/assets/images/components/desktop/pages/home/section-sports/bg-csport.webp',
    img: '/assets/images/components/desktop/pages/home/section-sports/csport-item.webp',
    title: '/assets/images/components/desktop/pages/home/section-sports/csports.webp',
    link: '/the-thao/csports'
  },
  {
    background: '/assets/images/components/desktop/pages/home/section-sports/bg-psport.webp',
    img: '/assets/images/components/desktop/pages/home/section-sports/psport-item.webp',
    title: '/assets/images/components/desktop/pages/home/section-sports/psports.webp',
    link: '/the-thao/psports'
  },
  {
    background: '/assets/images/components/desktop/pages/home/section-sports/bg-esports.webp',
    img: '/assets/images/components/desktop/pages/home/section-sports/esports-item.webp',
    title: '/assets/images/components/desktop/pages/home/section-sports/vitual-sports.webp',
    class: 'esport',
    link: '/the-thao/the-thao-ao'
  },
  {
    background: '/assets/images/components/desktop/pages/home/section-sports/bg-volta.webp',
    img: '/assets/images/components/desktop/pages/home/section-sports/volta-item.webp',
    title: '/assets/images/components/desktop/pages/home/section-sports/volta.webp',
    link: '/the-thao/tsports'
  },
  {
    background: '/assets/images/components/desktop/pages/home/section-sports/bg-volta.webp',
    img: '/assets/images/components/desktop/pages/home/section-sports/esport-item.webp',
    title: '/assets/images/components/desktop/pages/home/section-sports/esports.webp',
    link: '/the-thao/isports'
  }
]

export const LOBBY_SPORTS_MB: ILobbySport[] = [
  {
    background: '/assets/images/components/mobile/pages/home/section-sport/bg-ksport.webp',
    img: '/assets/images/components/mobile/pages/home/section-sport/ksport.webp',
    title: '/assets/images/components/mobile/pages/home/section-sport/text-ksport.webp',
    link: '/the-thao/ksports'
  },
  {
    background: '/assets/images/components/mobile/pages/home/section-sport/bg-csport.webp',
    img: '/assets/images/components/mobile/pages/home/section-sport/csport.webp',
    title: '/assets/images/components/mobile/pages/home/section-sport/text-csport.webp',
    link: '/the-thao/csports'
  },
  {
    background: '/assets/images/components/mobile/pages/home/section-sport/bg-psport.webp',
    img: '/assets/images/components/mobile/pages/home/section-sport/psport.webp',
    title: '/assets/images/components/mobile/pages/home/section-sport/text-psport.webp',
    link: '/the-thao/psports'
  },
  {
    background: '/assets/images/components/mobile/pages/home/section-sport/bg-saba.webp',
    img: '/assets/images/components/mobile/pages/home/section-sport/saba.webp',
    title: '/assets/images/components/mobile/pages/home/section-sport/text-saba.webp',
    link: '/the-thao/the-thao-ao',
    class: 'virtual-sport'
  }
]
