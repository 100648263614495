<template>
  <div class="section-support">
    <div class="section-item">
      <div class="section-item__title">LIÊN HỆ</div>
      <div class="section-item__content">
        <a :href="`${$config.TELEGRAM}`" target="_blank" class="item">
          <BaseImg src="/assets/images/components/mobile/pages/home/section-support/telegram.svg" alt="ic-telegram" />
          <span class="item__title">Telegram</span>
        </a>
        <a class="item" @click.prevent="$openLiveChat()">
          <BaseImg src="/assets/images/components/mobile/pages/home/section-support/live-chat.svg" alt="ic-live-chat" />
          <span class="item__title">Live Chat</span>
        </a>
        <a :href="`mailto:${$config.CONTACT_EMAIL}`" class="item">
          <BaseImg src="/assets/images/components/mobile/pages/home/section-support/email.svg" alt="ic-email" />
          <span class="item__title">Email</span>
        </a>
        <a :href="`${$config.TELEGRAM_247}`" target="_blank" class="item">
          <BaseImg src="/assets/images/components/mobile/pages/home/section-support/community.svg" alt="ic-community" />
          <span class="item__title">Cộng Đồng</span>
        </a>
      </div>
    </div>
    <div class="section-item">
      <div class="section-item__title">Thông tin chung</div>
      <div class="section-item__content">
        <nuxt-link v-for="(item, index) in info" :key="index" :to="item.link" class="item">
          <BaseImg :src="item.img" alt="icon" />
          <span class="item__title">{{ item.title }}</span>
        </nuxt-link>
      </div>
    </div>
    <div class="section-info">
      <div class="section-info__title">{{ dataSeo?.content_title }}</div>
      <p class="section-info__desc">
        {{ dataSeo?.content_description }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import { PAGE_URLS } from '~/config/page-url'
import { PAGE_PARAMS } from '~/constants/common'

const { $config, $openLiveChat } = useNuxtApp()

const info = [
  {
    img: '/assets/images/components/mobile/pages/home/section-support/news.svg',
    title: 'Tin Tức',
    link: PAGE_URLS.NEWS
  },
  {
    img: '/assets/images/components/mobile/pages/home/section-support/introduction.svg',
    title: 'Giới Thiệu',
    link: PAGE_URLS.INTRODUCTION
  },
  {
    img: '/assets/images/components/mobile/pages/home/section-support/policy.svg',
    title: 'Điều Khoản',
    link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.POLICY}`
  },
  {
    img: '/assets/images/components/mobile/pages/home/section-support/security.svg',
    title: 'Bảo Mật',
    link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.SECURITY}`
  }
]
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { seoData } = storeToRefs(store)
const route = useRoute()
const dataSeo = computed(() => {
  return seoData.value.find((item) => item.alias === '/')
})
</script>

<style scoped lang="scss" src="assets/scss/components/mobile/pages/home/section-support/index.scss"></style>
