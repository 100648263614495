import { PAGE_URLS } from '~/config/page-url'
import { GAME_PAGE_URL } from '~/constants/lobby'

interface ILobbyGame {
    img: string
    title: string
    class?: string
    jackpot?: string
    link: string
}

export const LOBBY_GAMES: ILobbyGame[] = [
  {
    img: '/assets/images/components/desktop/pages/home/section-lobby/table-game.webp',
    title: 'table games',
    link: GAME_PAGE_URL.table_game,
    jackpot: 'jackpotIngame'
  },
  {
    img: '/assets/images/components/desktop/pages/home/section-lobby/no-hu.webp',
    title: 'Nổ hũ',
    link: GAME_PAGE_URL.nohu,
    jackpot: 'jackpotNohu'
  },
  {
    img: '/assets/images/components/desktop/pages/home/section-lobby/lo-de.webp',
    title: 'lô đề',
    link: GAME_PAGE_URL.lotte
  },
  {
    img: '/assets/images/components/desktop/pages/home/section-lobby/card.webp',
    title: 'game bài',
    link: GAME_PAGE_URL.card_game
  },
  {
    img: '/assets/images/components/desktop/pages/home/section-lobby/fishing.webp',
    title: 'Bắn cá',
    link: GAME_PAGE_URL.shooting_fish,
    jackpot: 'jackpotFishing'
  },
  {
    img: '/assets/images/components/desktop/pages/home/section-lobby/slots.webp',
    title: 'Slots',
    link: GAME_PAGE_URL.slots
  },
  {
    img: '/assets/images/components/desktop/pages/home/section-lobby/lottery.webp',
    title: 'quay số',
    link: GAME_PAGE_URL.quay_so
  },
  {
    img: '/assets/images/components/desktop/pages/home/section-lobby/casino.webp',
    title: 'Live casino',
    link: GAME_PAGE_URL.all
  },
  {
    img: '/assets/images/components/desktop/pages/home/section-lobby/quick-game.webp',
    title: 'game nhanh',
    link: GAME_PAGE_URL.quick_game
  },
  {
    img: '/assets/images/components/desktop/pages/home/section-lobby/promotion.webp',
    title: 'khuyến mãi',
    link: PAGE_URLS.PROMOTION
  }
]
