<template>
  <div class="section-jackpot">
    <BaseImg
      src="/assets/images/components/desktop/pages/home/section-jackpot/text.png"
      alt="sum-jackpot"
      class="sum-jackpot"
      lazy
    />
    <client-only>
      <AnimateNumber
        class="value"
        :number="sumJackpot?.jackpotNohu + sumJackpot?.jackpotIngame + sumJackpot?.jackpotFishing"
      />
    </client-only>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import AnimateNumber from '~/components/common/animate-number.vue'
import { useAppStore } from '~/store/app'
import BaseImg from '~/components/common/base-img.vue'
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot, currentUser, isLogged } = storeToRefs(store)
</script>

<style scoped lang="scss" src="assets/scss/components/mobile/pages/home/section-jackpot/index.scss">

</style>
